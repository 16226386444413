import graphQLRequestAuth from "../graphqlRequestAuth";
import providerResetPassword from "./providerResetPassword";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchProviderResetPassword(input) {
    const datas = await graphQLRequestAuth(providerResetPassword,{password:input.password,passwordConfirmation:input.passwordConfirmation,code:input.code});
    return datas;
}
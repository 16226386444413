import LoginLayout from "@components/layout/login-layout.js";
import PasswordReset from "@components/password-reset/password-reset.js";
import "@scss/pages/password-reset/index.scss";
import fetchProviderForgotPassword from "../services/providerForgotPassword/fetchProviderForgotPassword";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { getValue } from "../utils/lodash";
import { toast } from "react-toastify";

export default function HomePage(props) {
	const [email, setEmail] = useState("");
	const simpleValidator = useRef(new SimpleReactValidator());
	const [, forceUpdate] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setEmail(value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formValid = simpleValidator.current.allValid();
		if (!formValid) {
			simpleValidator.current.showMessages();
			forceUpdate(1);
		} else {
			try {
				setIsLoading(true);
				let resp = await fetchProviderForgotPassword({email:email});
				if (resp) {
					toast.success(getValue(resp,'message','Email sent successfully'))
					props.history.push("/email-sent");
				}
			} catch (error) {
				setIsLoading(false);
			}
		}
	};
	return (
		<LoginLayout>
			<PasswordReset
				email={email}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				isLoading={isLoading}
				simpleValidator={simpleValidator}
			/>
		</LoginLayout>
	);
}

import DashboardLayout from '@components/layout/dashboard-layout.js';
import DashboardCard from '@components/dashboard/dashboard-page/dashboard-card.js';
import '@scss/pages/dashboard/index.scss';
import { useEffect, useState } from 'react';
import fetchDashboardDetails from '../services/dashboardDetails/fetchDashboardDetails';
import { getValue } from '../utils/lodash';
export default function Dashboard() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await fetchDashboardDetails();
      if (resp) {
        setIsLoading(false);
        console.log(resp);
        setData(getValue(resp, 'dashboardDetails', []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  console.log(data);
  return (
    <>
      <DashboardLayout>
        <h3 className="dashboard-main-wrapper-header__title">Leads Overview</h3>
        <div className="dashboard-home-wrapper">
          {isLoading ? (
            'Please wait...'
          ) : getValue(data, 'length', 0) > 0 ? (
            <DashboardCard data={data} />
          ) : (
            'No Data Found'
          )}
        </div>
      </DashboardLayout>
    </>
  );
}

import { GraphQLClient } from "graphql-request";
import { getValue } from "../utils/lodash";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { config } from "../env";
const ISSERVER = typeof window === "undefined";


/**
 * Executes an arbitrary GraphQL query against the Reaction API
 *
 * @param {Object} query - The GraphQL query to execute
 * @param {Object} variables - The query's variables
 * @returns {Object} data - the resulting query data
 */
function getCookiesMap(cookiesString) {
    return cookiesString.split(";")
        .map(function (cookieString) {
            return cookieString.trim().split("=");
        })
        .reduce(function (acc, curr) {
            acc[curr[0]] = curr[1];
            return acc;
        }, {});
}
export const authHeader = () => {
    // const authToken = !ISSERVER ? getCookiesMap(document.cookie)["authToken"] : "";
    const authToken = !ISSERVER ? localStorage.getItem('jwt'): "";

    if (authToken) {
        return { 'Authorization': 'Bearer ' + authToken };
    } else {
        return {};
    }
}
export default async function graphQLRequestAuth(query, variables) {
    const endpoint =`${config.API_URL}/graphql`;
    const graphQLClient = new GraphQLClient(endpoint, {
        timeout: 2000,
        // headers: authHeader()
    });
    try {
        const data = await graphQLClient.request(query, variables);
        return data;
    } catch (error) {
        if (getValue(error, "response.code", "") === 401) {
            localStorage.removeItem("authToken");
            window.location.href = "/login"
        } else {
            getValue(error, "response.errors", []).forEach(({ message }) => {
                // eslint-disable-next-line no-console
                toast.error(`${message}`);
            });
        }
        return null;
    }
}

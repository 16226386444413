export default `
mutation providerResetPassword($password:String!,$passwordConfirmation:String!,$code:String!) { 
    providerResetPassword(password:$password,passwordConfirmation:$passwordConfirmation,code:$code){ 
        jwt
        user{
            id
            email
        }
    }
    }
`;
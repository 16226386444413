import Aboutus from '@pages/about-us.js';
import PasswordReset from '@pages/password-reset.js';
import EmailSent from '@pages/email-sent.js';
import PasswordLogin from '@pages/login-pwd.js';
import Dashboard from '@pages/dashboard.js';
import FreshLeads from '@pages/fresh-leads.js';
import FreshLeadsInnerPage from '@pages/inside/fresh-leads-inner-page.js';
import FollowupOverdue from '@pages/followup-overdue.js';
import Interested from '@pages/interested.js';
import NotInterested from '@pages/not-interested.js';
import FollowUpInnerpage from '../pages/inside/followup-inner-page';
import IntrestedInnerpage from '../pages/inside/interested-inner-page';
import NotIntrestedInnerpage from '../pages/inside/not-interested-inner-page';

const routes = [
  { path: '/about-us', name: 'Aboutus', component: Aboutus },
  { path: '/password-reset', name: 'PasswordReset', component: PasswordReset },
  { path: '/email-sent', name: 'EmailSent', component: EmailSent },
  { path: '/provider/reset-password', name: 'PasswordLogin', component: PasswordLogin },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/fresh-leads', name: 'FreshLeads', component: FreshLeads },
  {
    path: '/fresh-leads-page/:id',
    name: 'FreshLeadsInnerPage',
    component: FreshLeadsInnerPage,
  },
  {
    path: '/followup-page/:id',
    name: 'FollowUpInnerpage',
    component: FollowUpInnerpage,
  },
  {
    path: '/interested-page/:id',
    name: 'IntrestedInnerpage',
    component: IntrestedInnerpage,
  },
  {
    path: '/not-interested-page/:id',
    name: 'NotIntrestedInnerpage',
    component: NotIntrestedInnerpage,
  },
  {
    path: '/followup-overdue',
    name: 'FollowupOverdue',
    component: FollowupOverdue,
  },
  { path: '/interested', name: 'Interested', component: Interested },
  { path: '/not-interested', name: 'NotInterested', component: NotInterested },
];

export default routes;

import React from 'react';

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <header>header</header>
      <div className="navigationWrapper">
        <nav>navigation</nav>
        <main>{children}</main>
      </div>
    </React.Fragment>
  );
};
export default Layout;

import '@scss/components/home-page/login-form.scss';
import InputField from '../../common/InputRuleField';
import {getValue} from '../../utils/lodash'

export default function Login(props) {
  return (
    <div className="login-wrapper">
      <img
        src="/images/home-page/logo.svg"
        className="img-fluid"
        alt=" "
        className="login-wrapper__logo-img"
      />
      <h2 className="login-wrapper__title">Login</h2>
      <div className="form-group">
        <label htmlFor="email-address">Email Address</label>
        <InputField
          inputType="TEXT"
          id="email-address"
          name='email'
          placeholder="Enter Email ID"
          value={getValue(props,'request.email')}
          onChange = {props.handleChange}
          className="login-wrapper__form-element form-control"
          validator={props.simpleValidator}
          label='Email Address'
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <InputField
          inputType="PASSWORD"
          id="pwd"
          name='pwd'
          value={getValue(props,'request.pwd')}
          onChange = {props.handleChange}
          placeholder="Enter Password"
          className="form-control login-wrapper__form-element"
          validator={props.simpleValidator}
          label='Password'
        />
      </div>
      <div className="login-wrapper__cta-wrapper d-flex align-items-center justify-content-between">
        <a href="password-reset">
          <button className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-secondary-btn">
            Forgot password?
          </button>
        </a>
        {props.isLoading?
        <button className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-primary-btn">
        Please wait...
      </button>: 
        <button className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-primary-btn" onClick={props.handleSubmit}>
          Login
        </button>}
      </div>
    </div>
  );
}

import graphQLRequestAuth from "../graphqlRequestAuth";
import providerForgotPassword from "./providerForgotPassword";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchProviderForgotPassword(input) {
    const datas = await graphQLRequestAuth(providerForgotPassword,input);
    return datas;
}
import graphQLRequest from "../graphqlRequest";
import providerLeadsCount from "./providerLeadsCount";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchProviderLeadsCount(where) {
    const datas = await graphQLRequest(providerLeadsCount,{where});
    return datas;
}
import graphQLRequest from "../graphqlRequest";
import dashboardDetails from "./dashboardDetails";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchDashboardDetails() {
    const datas = await graphQLRequest(dashboardDetails);
    return datas;
}
import '@scss/components/password-reset/password-reset.scss';
import { NavLink } from 'react-router-dom';

export default function PasswordReset() {
  return (
    <div className="password-reset-wrapper">
      <img
        src="/images/home-page/logo.svg"
        className="img-fluid"
        alt=" "
        className="login-wrapper__logo-img"
      />

      <h2 className="password-reset-wrapper__title">Email sent</h2>
      <p className="password-reset-wrapper__text mb-0">Check your </p>
      <p className="password-reset-wrapper__text">
        Venkatesh@Healthtunnel.in inbox for instructions from us on how to reset
        your password.
      </p>
      <div className="password-reset-wrapper__cta-wrapper">
        <NavLink to="/">
          <button className="password-reset-wrapper__cta-btn password-reset-wrapper__cta-btn--theme-primary-btn w-100">
            ok
          </button>
        </NavLink>
      </div>
    </div>
  );
}

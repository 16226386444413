import graphQLRequestAuth from "../graphqlRequestAuth";
import loginProviderUser from "./loginProviderUser";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchLoginProviderUser(input) {
    const datas = await graphQLRequestAuth(loginProviderUser,input);
    return datas;
}
import { NavLink } from "react-router-dom";
import { getValue } from "../../utils/lodash";
import Moment from "react-moment";

export default function DashboardCard(props) {
	return (
		<NavLink
			to={`${getValue(props, `inside`, "")}/${getValue(
				props,
				`commonCard.id`,
				""
			)}${process.browser && window.location.search}`}
			style={{ cursor: "pointer" }}
		>
			<div className="dashboard-main-wrapper-card d-flex">
				<img
					src={`${
						props.cardIcon || "/images/dashboard/fresh-leads/leads-icon.svg"
					}`}
					className="img-fluid"
				/>
				<div className="dashboard-main-wrapper-card__details">
					<div className="dashboard-main-wrapper-card__list">
						<ul>
							<li>{getValue(props, `commonCard.user.full_name`, "")}</li>
							<li className="dot"></li>
							<li>{getValue(props, `commonCard.user.phone`, "")}</li>
							<li className="dot"></li>
							<li>{getValue(props, `commonCard.user.email`, "")}</li>
						</ul>
					</div>
					<div className="dashboard-main-wrapper-card__list dashboard-main-wrapper-card__list--font">
						<ul>
							<li>
								<Moment format="DD-MMM-YYYY hh:mm a">
									{getValue(props, `commonCard.createdAt`, "")}
								</Moment>
							</li>
							<li className="dot"></li>
							<li>
								{getValue(
									props,
									`commonCard.provider.sub_category.category.name`,
									""
								)}
							</li>
							<li className="dot"></li>
							<li>
								{getValue(props, `commonCard.provider.sub_category.name`, "")}
							</li>
							<li className="dot"></li>
							<li>{getValue(props, `commonCard.provider.name`, "")}</li>
							{getValue(props, `commonCard.city`, "") ? (
								<>
									<li className="dot"></li>
									<li>{getValue(props, `commonCard.city`, "")}</li>
								</>
							) : (
								""
							)}
							{getValue(props, `commonCard.zip_code`, "") ? (
								<>
									<li className="dot"></li>
									<li>{getValue(props, `commonCard.zip_code`, "")}</li>
								</>
							) : (
								""
							)}
						</ul>
					</div>
				</div>
			</div>
		</NavLink>
	);
}

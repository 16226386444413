import graphQLRequest from "../graphqlRequest";
import providerLead from "./providerLead";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchProviderLead(id) {
    const datas = await graphQLRequest(providerLead,id);
    return datas;
}
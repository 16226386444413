import LoginLayout from "@components/layout/login-layout.js";
import PasswordResetLogin from "@components/login-pwd/login-pwd.js";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import fetchProviderResetPassword from "../services/providerResetPassword/fetchProviderResetPassword";
import { getValue } from "../utils/lodash";
import "@scss/pages/password-reset/index.scss";
import { toast } from "react-toastify";

export default function HomePage(props) {
	const [request, setRequest] = useState({
		password: "",
		passwordConfirmation: "",
    code:window.location.href.split('code=')[1]
	});
	const simpleValidator = useRef(new SimpleReactValidator());
	const [, forceUpdate] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setRequest({
			...request,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
    if(request.password === request.passwordConfirmation){
		const formValid = simpleValidator.current.allValid();
		if (!formValid) {
			simpleValidator.current.showMessages();
			forceUpdate(1);
		} else {
			try {
				setIsLoading(true);
				let resp = await fetchProviderResetPassword( request );
				if (resp) {
					console.log(resp);
					setIsLoading(false);
					localStorage.setItem(
						"jwt",
						getValue(resp, "loginProviderUser.jwt", "")
					);
					localStorage.setItem(
						"user",
						JSON.stringify(getValue(resp, "loginProviderUser.user", ""))
					);
					props.history.push("/dashboard");
				}else{
          setIsLoading(false);
        }
			} catch (error) {
				setIsLoading(false);
			}
		}
  }else{
    toast.error('Passwords should be equal')
  }
	};

	return (
		<LoginLayout>
			<PasswordResetLogin
				request={request}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				isLoading={isLoading}
				simpleValidator={simpleValidator}
			/>
		</LoginLayout>
	);
}

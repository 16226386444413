export default `
query providerLeads($sort:String,$limit:Int,$start:Int,$where:JSON){
  providerLeads(sort:$sort,limit:$limit,start:$start,where:$where){
    id
    createdAt
    user{
      id
      full_name
      email
      phone
    }
    city
    zip_code
    provider{
      id 
      name
      site_url
      logo{
        url
      }
      description
      priority
      is_important
      city{
        id
        name
        city_latitude
        city_longitude
      }
      state{
        id
        name
      }
      country{
        id
        name
      }

      sub_category{
        name
        description
        web_image{
          url
        }
        app_image{
          url
        }
        category{
          id
          name
          description

        }
      }
    }
  }
}
`;
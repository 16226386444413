import React, { useEffect, useState } from "react";
import Card from "@components/dashboard/common-dashboard-card.js";
import DashboardLayout from "@components/layout/dashboard-layout.js";
import fetchProviderLeads from "../services/providerLeads/fetchProviderLeads";
import { getValue } from "../utils/lodash";
import Pagination from "react-js-pagination";
import fetchProviderLeadsCount from "../services/providerLeadsCount/fetchProviderLeadsCount";
import LoadingCard from "@components/loading/loading-card";
import NotFound from "@components/not-found/index.js";
import moment from "moment";
import qs from "query-string";
import Header from "@components/dashboard/fresh-leads/header.js";

export default function FreshLeads(props) {
	// ************************************************************** //
	// *******************    Use Effect Section   ****************** //
	// ************************************************************** //

	useEffect(() => {
		getData(limit, start);

		const urlSearchParams = new URLSearchParams(
			process.browser && window.location.search
		);
		const params = Object.fromEntries(urlSearchParams.entries());

		let page = window.location.search.split("?page=")[1];
		let activePage = page ? page.split("0") : 1;
		setStart(parseFloat(page ? page : 1));
		setActivePage(
			activePage
				? parseFloat(page) > 9
					? parseFloat(activePage) + 1
					: parseFloat(activePage)
				: 1
		);
		if (params.createdAt_gte && params.createdAt_lte) {
			setDateRange([
				new Date(params.createdAt_gte),
				new Date(params.createdAt_lte),
			]);
		}
	}, []);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(
			process.browser && window.location.search
		);
		const params = Object.fromEntries(urlSearchParams.entries());
		let page = window.location.search.split("?page=")[1];
		let activePage = page ? page.split("0") : 1;
		setStart(parseFloat(page ? page : 1));
		setActivePage(
			activePage
				? parseFloat(page) > 9
					? parseFloat(activePage) + 1
					: parseFloat(activePage)
				: 1
		);
		if (params.createdAt_gte && params.createdAt_lte) {
			setDateRange([
				new Date(params.createdAt_gte),
				new Date(params.createdAt_lte),
			]);
		}
		getData(limit, start);
	}, [process.browser && window.location.href]);

	// ************************************************************** //
	// *******************    Use State Section   ****************** //
	// ************************************************************** //

	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	const [freshLeads, setFreshLeads] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	// pagination
	const [totalCount, setTotalCount] = useState(0);
	const [activePage, setActivePage] = useState(1);
	const [start, setStart] = useState(
		window.location.search.split("?page=")[1]
			? window.location.search.split("?page=")[1]
			: 1
	);
	const [limit] = useState(10);

	const handleClearDate = () => {
		setDateRange([null, null]);
		const urlSearchParams = new URLSearchParams(
			process.browser && window.location.search
		);
		const params = Object.fromEntries(urlSearchParams.entries());
		let payload = {
			page: params.page,
		};
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		props.history.push(`/followup-overdue?${queryRequest}`);
	};

	const handleApplyDateFilter = () => {
		let createdAt_gte = moment(startDate).format("YYYY-MM-DD");
		let createdAt_lte = moment(endDate).format("YYYY-MM-DD");

		let payload = {
			page: "1",
			createdAt_gte: createdAt_gte,
			createdAt_lte: createdAt_lte,
		};
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		props.history.push(`/followup-overdue?${queryRequest}`);
	};

	// ************************************************************** //
	// **********************   API Section     ********************** //
	// ************************************************************** //

	const getData = async (limit, start) => {
		let starts = parseFloat(start) - 1;
		const urlSearchParams = new URLSearchParams(
			process.browser && window.location.search
		);
		const params = Object.fromEntries(urlSearchParams.entries());
		try {
			setIsLoading(true);
			let where = {
				status: "follow_up",
				createdAt_gte: params.createdAt_gte
					? params.createdAt_gte
					: startDate
					? moment(startDate).format("YYYY-MM-DD")
					: "",
				createdAt_lte: params.createdAt_lte
					? params.createdAt_lte
					: endDate
					? moment(endDate).format("YYYY-MM-DD")
					: "",
			};

			Object.keys(where).forEach((key) => {
				if (!where[key] || where[key] === undefined) {
					delete where[key];
				}
			});

			let resp = await fetchProviderLeads(limit, parseFloat(starts), where);
			let count = await fetchProviderLeadsCount(where);

			if (resp) {
				setIsLoading(false);
				console.log(resp);
				setFreshLeads(getValue(resp, "providerLeads", []));
				setTotalCount(getValue(count, `providerLeadsCount`, 0));
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	// ************************************************************** //
	// *****************   Pagination Section      ****************** //
	// ************************************************************** //

	const handlePageChange = (start) => {
		setActivePage(start);
		if (parseFloat(start) === 1) {
			setStart(start);
			// getData(limit,start);
			props.history.push({
				pathname: "/followup-overdue",
				search: "?page=" + start,
			});
			getData(limit, start);
		} else {
			let currentPage =
				(parseFloat(start) - 1) * 10 + (parseFloat(start) - parseFloat(start));
			setStart(currentPage);
			// getData(limit,currentPage);
			props.history.push({
				pathname: "/followup-overdue",
				search: "?page=" + currentPage,
			});
			getData(limit, currentPage);
		}
	};

	return (
		<DashboardLayout>
			<div className="dashboard-main-wrapper-header d-flex justify-content-between align-items-center">
				<h3 className="dashboard-main-wrapper-header__title">
					Followup Overdue
				</h3>
				{/* <select
					type="select"
					className="dashboard-main-wrapper-header__select-date"
				>
					<option>Jun 11, 2021 - Jun 17, 2021</option>
				</select> */}
				<Header
					dateRange={dateRange}
					setDateRange={setDateRange}
					startDate={startDate}
					endDate={endDate}
					handleClearDate={handleClearDate}
					handleApplyDateFilter={handleApplyDateFilter}
				/>
			</div>
			{isLoading ? (
				[1, 2, 3, 4].map((index) => {
					return <LoadingCard key={`fresh-leads-${index}`} />;
				})
			) : freshLeads.length > 0 ? (
				freshLeads.map((freshLeadsObj, index) => {
					return (
						<Card
							key={`leads${index}`}
							cardIcon="/images/dashboard/followup-overdue/followup-icon.svg"
							commonCard={freshLeadsObj}
							inside={`/followup-page`}
						/>
					);
				})
			) : (
				<NotFound />
			)}
			{totalCount > 10 ? (
				<Pagination
					activePage={activePage}
					itemsCountPerPage={10}
					totalItemsCount={totalCount}
					pageRangeDisplayed={5}
					onChange={handlePageChange}
				/>
			) : (
				""
			)}
		</DashboardLayout>
	);
}

export default `
mutation loginProviderUser($input:LoginProviderUser!) { 
    loginProviderUser(input:$input){ 
        jwt
        user{
            id
            name
            email
            provider
        }
    }
    }
`;
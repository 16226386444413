export default `
query providerLeadComments($where:JSON){
  providerLeadComments(where:$where){
    id
    createdAt
    updatedAt
    comment
    provider_user{
      id
      name
      email
      provider
    }
    provider_lead{
      id
      user{
        id
        full_name
      }
    }
  }
}
`;
import { useEffect, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

export default function DashboardHeader() {
	const [dashboardNavigationArray] = useState([
		{
			linkUrl: '/dashboard',
			imageUrl: '/images/dashboard/navigation/dashboard.svg',
			title: 'Dashboard',
		},
		{
			linkUrl: '/fresh-leads',
			imageUrl: '/images/dashboard/navigation/offer.svg',
			title: ' Fresh Leads',
		},
		{
			linkUrl: '/followup-overdue',
			imageUrl: '/images/dashboard/navigation/pending.svg',
			title: 'Followup Overdue',
		},
		{
			linkUrl: '/interested',
			imageUrl: '/images/dashboard/navigation/thumb-up.svg',
			title: 'Interested',
		},
		{
			linkUrl: '/not-interested',
			imageUrl: '/images/dashboard/navigation/thumb-down.svg',
			title: 'Not Interested',
		},
	]);

	const match = useRouteMatch();

	useEffect(() => {
		document.addEventListener('click', function (e) {
			if (e.target && e.target.id == 'toogle-left-navigation-id_js') {
				document
					.querySelector('.dashboard-navigation')
					.classList.toggle('dashboard-navigation--collapse');
			}
		});
	});

	return (
		<div className="dashboard-navigation">
			<ul>
				{dashboardNavigationArray.map((tempObj, index) => {
					return (
						<li
							className="dashboard-navigation__list"
							key={`dashboard${index}`}
						>
							<NavLink
								to={tempObj.linkUrl}
								className={`dashboard-navigation__link-nav ${
									match.path === tempObj.linkUrl
										? 'dashboard-navigation__link-nav--active'
										: ''
								}`}
							>
								<img
									src={tempObj.imageUrl}
									className="dashboard-navigation__image"
									alt=" "
								/>
								{tempObj.title}
							</NavLink>
						</li>
					);
				})}
			</ul>
			<div className="dashboard-navigation__icon d-flex">
				<img
					src="/images/generic-images/left-arrow.svg"
					if="toogle-left-navigation-id_js"
					className="dashboard-navigation__image-icon img-fluid cursor-pointer toogle-left-navigation_js"
				/>
			</div>
		</div>
	);
}

import React from "react";
import ReactDOM from "react-dom";
import App from "./routes/index";
import { ToastContainer, Slide } from "react-toastify";
import "@scss/common/base.scss";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from 'react-apollo';

// Apollo client setup
const client = new ApolloClient({
	uri: "https://backend.healthtunnel.com/graphql",
});
ReactDOM.render(
	<ApolloProvider client={client}>
			<React.StrictMode>
				<ToastContainer
					theme="dark"
					position="bottom-center"
					autoClose={15000}
					transition={Slide}
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					limit={2}
					pauseOnFocusLoss
					pauseOnHover
				/>
				<App />
			</React.StrictMode>
	</ApolloProvider>,
	document.getElementById("root")
);

import graphQLRequest from "../graphqlRequest";
import providerLeadComment from "./providerLeadComments";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchProviderLeadComment(where) {
    const datas = await graphQLRequest(providerLeadComment,where);
    return datas;
}
export default `
query providerLead($id:ID!){
  providerLead(id:$id){
    id
    status
    amount
    createdAt
    city
    zip_code
    user{
      id
      full_name
      email
      phone
    }
    provider{
      id 
      name
      site_url
      logo{
        url
      }
      description
      priority
      is_important
      city{
        id
        name
        city_latitude
        city_longitude
      }
      state{
        id
        name
      }
      country{
        id
        name
      }

      sub_category{
        name
        description
        web_image{
          url
        }
        app_image{
          url
        }
        category{
          id
          name
          description

        }
      }
    }
  }
}
`;
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routes from '@/routes/routes';
import Homepage from '@pages/home-page.js';

export default function HealthtunnelRouting() {
  return (
    <>
      <Router>
        <Switch>
          {routes.map((routeObj, index) => {
            return (
              <Route
                key={index}
                path={routeObj.path}
                exact
                component={routeObj.component}
              />
            );
          })}
          <Route path="/" component={Homepage} />
          {/* <Route path="*" component={Pagenotfound} /> */}
        </Switch>
      </Router>
    </>
  );
}
